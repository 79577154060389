const ApiRoutes = {
    BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
    BASE_DATA_URL: process.env.NEXT_PUBLIC_API_BASE_DATA_URL,
    STRATEGY_URL: '/strategy',
    STRATEGY_BASE_URL: `/strategy`,
    STRATEGY_SUMMERY_URL: `/strategy/summery`,
    CREATE_STRATEGY_URL: `/strategy/list-create`,

    // Marketplace URLs
    MARKETPLACE_URL: `/strategy/list-create/market-place`,
    MARKETPLACE_LIST_URL: `/strategy/summery/market-place/all`,
    MARKETPLACE_USER_BASE_URL: `/strategy/market-place/user-save`,
    USER_MARKET_STRATEGIES_GET_URL: `/strategy/summery/market-place/all`,

    // Exchanges URLs
    EXCHANGE_URL: `/exchange`,
    EXCHANGE_BULK_SAVE_URL: `/exchange/bulk-save`,
    EXCHANGE_LIST_URL: `/exchange/list`,
    EXCHANGE_IP_ADDRESS_URL: `/exchange/server-ipaddress`,
    WALLET_AMOUNT: `/exchange/wallet/{1}`,

    // Bot URLs
    BOT_URL: `/bot`,
    BACKTEST_BOTS_LIST: `/bot/list-create/back-test`,
    BACKTEST_BOTS_LIST_SUMMARY: `/bot/summary/back-test`,
    TRADE_BOTS_LIST_SUMMARY: `/bot/summary/trade`,
    TRADE_BOT_URL: `/bot/list-create/trade`,
    BOT_EVENT_URL: `/bot/event`,
    RESTORE_BOT: `/bot/restore`,
    BACKTEST_BOT_RESULT_SUMMERY: '/trade/back-test/summery/{1}',
    BACKTEST_BOT_RESULT_BACKUP: '/trade/backup/{1}',
    BACKTEST_BOT_RESULT_LIST: '/trade/back-test/{1}',
    TRADE_TIME_FRAME: '/trade/timeframe/retrieve',
    BOT_HISTORY: `/trade/history/back-test`,
    BOT_HISTORY_REMOVE: `/bot/remove-from-history`,
    BOT_HISTORY_DETAIL: '/bot/history-details/{1}',
    TRADE_HISTORY: `trade/history/trade`,

    // Overview
    OVERVIEW_OPEN_ORDERS: `/trade/overview/open-orders`,

    // Auth URLs
    USER_URL: `/user`,
    SIGNUP_URL: `/user/register`,
    VERIFY_URL: `/user/verify-email`,
    SIGNIN_URL: `/user/login`,
    PROFILE_URL: `/user/edit-personal-info`,
    WHO_AM_I: `/user/profile`,
    USER_DETAILS: 'user/details',

    // Assets URLs
    ASSETS_URL: `/assets`,
    USDT_FUTURE_ASSETS_URL: `/assets/{1}/usdt-future`,
    COIN_FUTURE_ASSETS_URL: `/assets/{1}/coin-future`,
    SPOT_ASSETS_URL: `/assets/{1}/spot`,

    // Notification URLs
    NOTIFICATION_LIST: `/notification/list`,
    NOTIFICATION_READ: `/notification/read/{1}`,
    NOTIFICATION_READ_ALL: `/notification/read-all`,

    // WS
    WS_BOT_PROCESS: 'bot-process/percent?backtest-{1}',
    WS_ORDER_LIVE: 'order/realtime?type=live&position={1}&auth={2}',
} as const;

export default ApiRoutes;
