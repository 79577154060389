import React, { useState } from 'react';
import { Bell, CheckCheck } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import Typography from '@/components/ui/typography';
import Routes from '@/constants/Routes';
import useApi from '@/utils/api/useApi';
import ApiRoutes from '@/utils/api/urls';
import { INotification } from '@/utils/api/ApiResponseTypes/notification';
import { updateMarkAllAsRead } from '@/actions/notification';
import { useToast } from '@/components/ui/use-toast';
import dayjs from 'dayjs';
import Config from '@/constants/Config';
import { cn } from '@/utils/utils';
import Link from 'next/link';
import NoData from '@/components/Common/NoData';
import RingLoading from '@/components/Loaders/RingLoading';
const Notification = () => {
    const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState(false);
    const { toast } = useToast();

    const { camelCaseData, refetch, isLoading } = useApi<INotification[]>({
        url: ApiRoutes.NOTIFICATION_LIST,
        queryKey: ['notifications_list_header'],
    });

    const isAnyUnreadMessage =
        camelCaseData?.filter(notification => !notification.isRead).length > 0;
    const isData = camelCaseData && camelCaseData?.length !== 0;

    const handleMarkAllAsRead = () => {
        setIsMarkingAllAsRead(true);
        updateMarkAllAsRead().then(() => {
            toast({
                title: 'Success',
                description: 'All notifications have been marked as read',
                variant: 'success',
            });
            refetch();
            setIsMarkingAllAsRead(false);
        });
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant='ghost' size='icon' className='relative'>
                    <Bell size={22} strokeWidth={1} />
                    {isAnyUnreadMessage && (
                        <span className='absolute right-1 top-1 h-3 w-3 rounded-full bg-red-500' />
                    )}
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className='w-80'>
                <div className='flex items-center justify-between'>
                    <DropdownMenuLabel>Notifications</DropdownMenuLabel>
                    <Button
                        disabled={isMarkingAllAsRead || !isAnyUnreadMessage}
                        isLoading={isMarkingAllAsRead}
                        variant='link'
                        className='gap-x-1'
                        onClick={handleMarkAllAsRead}
                    >
                        <CheckCheck size={14} />
                        Mark All as Read
                    </Button>
                </div>
                <DropdownMenuSeparator />
                <div className='minimal-scrollbar max-h-96 overflow-y-auto'>
                    {isLoading ? (
                        <div className='flex h-full items-center justify-center py-5'>
                            <RingLoading size='40' speed='0.8' />
                        </div>
                    ) : !isData ? (
                        <NoData width={50} height={50} text='All Clear' textClassName='text-base' />
                    ) : (
                        camelCaseData?.map(({ id, title, isRead, sendDate, message }) => {
                            return (
                                <DropdownMenuItem
                                    key={id}
                                    className='mb-1 flex justify-between hover:bg-muted'
                                >
                                    <Link href={Routes.NOTIFICATION}>
                                        <div className='flex flex-col'>
                                            <div className='flex items-start gap-x-1'>
                                                <span
                                                    className={cn(
                                                        'mt-1 rounded-full p-1',
                                                        !isRead && 'border-2 border-primary'
                                                    )}
                                                />

                                                <Typography variant='h6'>{title}</Typography>
                                            </div>
                                            {title !== 'backtest' && (
                                                <Typography className='ml-4' variant='p'>
                                                    {message}
                                                </Typography>
                                            )}
                                            <Typography
                                                variant='span'
                                                className='ml-4 mt-2 text-muted-foreground'
                                            >
                                                {dayjs(sendDate).format(Config.DATE_TIME_FORMAT)}
                                            </Typography>
                                        </div>
                                    </Link>
                                </DropdownMenuItem>
                            );
                        })
                    )}
                </div>
                <DropdownMenuSeparator />
                <Link href={Routes.NOTIFICATION}>
                    <Button variant='link' className='gap-x-1'>
                        View All Notifications
                    </Button>
                </Link>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export default Notification;
