import { useQuery } from '@tanstack/react-query';
import apiClient from './http-common';
import { AxiosError, AxiosResponse } from 'axios';
import { makeArrayCamelCase, makeObjectCamelCase } from '@/utils/utils';
import { useMemo } from 'react';
import useAuth from '@/hooks/useAuth';

type Props = {
    url: string;
    baseUrl?: string;
    queryKey: string[];
    params?: any;
    retry?: boolean | number;
    method?: 'get';
    load?: boolean;
    refetchInterval?: number;
    onError?: (err: any) => void;
};

const useApi = <T>(props: Props) => {
    const {
        url,
        queryKey,
        params,
        refetchInterval,
        method = 'get',
        retry = false,
        load = true,
        baseUrl,
    } = props;
    const { isAuthenticated, update, accessToken } = useAuth();

    const apiResult = useQuery<AxiosResponse<T>, AxiosError>({
        queryKey: queryKey,
        queryFn: async ({ signal }) => {
            return await apiClient(url, {
                baseURL: baseUrl ?? process.env.NEXT_PUBLIC_API_BASE_URL,
                method: method,
                params: params,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${accessToken}`,
                },
                signal,
            });
        },
        retry,
        refetchInterval,
        enabled: !isAuthenticated ? false : load,
        refetchOnWindowFocus: false,
    });

    if (apiResult.isError) {
        if (apiResult.error.status === 401) {
            update();
        }
    }

    const camelCaseData: T = useMemo(
        () => {
            let tempData;
            if (apiResult?.data?.data) {
                if (Array.isArray(apiResult.data.data)) {
                    tempData = makeArrayCamelCase(apiResult.data.data);
                } else if (typeof apiResult.data.data === 'object') {
                    tempData = makeObjectCamelCase(apiResult.data.data);
                }
            }
            return tempData;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [apiResult.data]
    );

    return { ...apiResult, camelCaseData: camelCaseData as typeof camelCaseData };
};

export default useApi;
