import noDataIcon from '@/assets/img/NoData.png';
import { cn } from '@/utils/utils';
import Image from 'next/image';

interface INoDataProps {
    text?: string;
    width?: number;
    height?: number;
    textClassName?: string;
}
const NoData = (props: INoDataProps) => {
    const { text = 'No Data', width = 100, height = 100, textClassName } = props;
    return (
        <div className='flex flex-col items-center justify-center space-y-5 py-2'>
            <Image
                className='mx-auto'
                priority
                src={noDataIcon}
                width={width}
                height={height}
                alt='no data'
            />
            <h1 className={cn('text-lg font-bold', textClassName)}>{text}</h1>
        </div>
    );
};

export default NoData;
