import React from 'react';

interface InfinityProps {
    size?: string;
    speed?: string;
    stroke?: string;
    theme?: string;
}
const RingLoading = (props: InfinityProps) => {
    const { size = '65', speed = '1.3', theme = 'white', stroke = '2.8' } = props;

    return (
        <l-ring-2
            size={size}
            stroke-length='0.15'
            bg-opacity='0.1'
            speed={speed}
            stroke={stroke}
            color={`${theme === 'dark' ? 'white' : 'black'}`}
        />
    );
};

export default RingLoading;
